import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SvgBoxDeco from 'src/components/sport/svgBoxDeco'
import parse from 'html-react-parser'

import OfferIcon1 from 'src/images/benefitsIcons/icon-dlaczego-dokladnie-tak-jak-chcesz.svg'
import OfferIcon2 from 'src/images/benefitsIcons/icon-dlaczego-obsluga-z-gornej-polki.svg'
import OfferIcon3 from 'src/images/benefitsIcons/icon-dlaczego-unikatowy-produkt.svg'
import OfferIcon4 from 'src/images/benefitsIcons/icon-dlaczego-zawsze-na-czas.svg'

const Benefits = ({ page }) => {
  const graphqlResult = useStaticQuery(graphql`
    query SportBenefitsPosts {
      wpCptSportPage(AcfGatsbyId: {pageid: {eq: "benefits"}}) {
				acfsportpagebenefits {
					benefit1TitleSportPageBenefits
          benefit1TextSportPageBenefits

          benefit2TitleSportPageBenefits
          benefit2TextSportPageBenefits

          benefit3TitleSportPageBenefits
          benefit3TextSportPageBenefits

          benefit4TitleSportPageBenefits
          benefit4TextSportPageBenefits

          subheaderSportPageBenefits
        }
      }
    }
  `)

  const benefits = graphqlResult.wpCptSportPage.acfsportpagebenefits

  return (
    <div>
      <div className="section-header">
        <h1>
          <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
            {page.title}
          </span>
        </h1>
      </div>
      <div className="box box--benefits boxWithDeco">
        <div className={`boxDeco`}>
          <SvgBoxDeco />
        </div>
        {benefits.subheaderSportPageBenefits && <h2>{benefits.subheaderSportPageBenefits}</h2>}
        {page.content && <div className={`wpContent`} dangerouslySetInnerHTML={{ __html: page.content }}></div>}
        <div className="benefits-list">
          <div className="card">
            <div className="card_image">
              <img alt="decoration" src={OfferIcon3}></img>
            </div>
            <div>
              <h4>{benefits.benefit1TitleSportPageBenefits}</h4>
              {/* {benefits.benefit1TextSportPageBenefits && <p dangerouslySetInnerHTML={{ __html: benefits.benefit1TextSportPageBenefits }}>} */}
              {/* <p dangerouslySetInnerHTML={{ __html: benefits.benefit1TextSportPageBenefits }}> */}
              {parse(benefits.benefit1TextSportPageBenefits || '')}
            </div>
          </div>
          <div className="card">
            <div className="card_image">
              <img alt="decoration" src={OfferIcon4}></img>
            </div>
            <div>
              <h4>{benefits.benefit2TitleSportPageBenefits}</h4>
              {/* {benefits.benefit2TextSportPageBenefits && <p dangerouslySetInnerHTML={{ __html: benefits.benefit2TextSportPageBenefits }}>} */}
              {/* <p dangerouslySetInnerHTML={{ __html: benefits.benefit2TextSportPageBenefits }}> */}
              {parse(benefits.benefit2TextSportPageBenefits || '')}
            </div>
          </div>
          <div className="card">
            <div className="card_image">
              <img alt="decoration" src={OfferIcon2}></img>
            </div>
            <div>
              <h4>{benefits.benefit3TitleSportPageBenefits}</h4>
              {/* {benefits.benefit3TextSportPageBenefits && <p dangerouslySetInnerHTML={{ __html: benefits.benefit3TextSportPageBenefits }}>} */}
              {/* <p dangerouslySetInnerHTML={{ __html: benefits.benefit3TextSportPageBenefits }}> */}
              {parse(benefits.benefit3TextSportPageBenefits || '')}
            </div>
          </div>
          <div className="card">
            <div className="card_image">
              <img alt="decoration" src={OfferIcon1}></img>
            </div>
            <div>
              <h4>{benefits.benefit4TitleSportPageBenefits}</h4>
              {/* {benefits.benefit4TextSportPageBenefits && <p dangerouslySetInnerHTML={{ __html: benefits.benefit4TextSportPageBenefits }}>} */}
              {/* <p dangerouslySetInnerHTML={{ __html: benefits.benefit4TextSportPageBenefits }}> */}
              {parse(benefits.benefit4TextSportPageBenefits || '')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Benefits