import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import SvgBoxDeco from 'src/components/sport/svgBoxDeco'
import { navigate } from "gatsby"


const ThankYou = ({ page, location }) => {
  const graphqlResult = useStaticQuery(graphql`
    query SportThankyouPosts {
      thankyou: wpCptSportPage(AcfGatsbyId: {pageid: {eq: "thank-you"}}) {      
				acfsportpagethankyou {
					subheaderSportPageThankYou
          subheader2SportPageThankYou
          ctaTextSportPageThankYou
          ctaLinkSportPageThankYou
          redirectLabelSportPageThankYou
          redirectTimeSportPageThankYou
        }
      }
    }
  `)

  // useEffect(() => {

  //   if(typeof window !== 'undefined') {

  //     if(typeof window.dataLayer !== 'undefined') {
        
  //       window.dataLayer.push({
  //         // 'event' : 'contactFormSubmission-Sport',
  //         'event' : 'pageview',
  //         'pageType' : 'ThankYouPage-Sport',
  //       });
        
  //       console.log('DATALAYER PUSH')
  //     }
  //   }
  // }, [])

  const redirect = location.search !== '' ? location.search.slice(1) : graphqlResult.thankyou.acfsportpagethankyou.ctaLinkSportPageThankYou === null ? '/' : graphqlResult.thankyou.acfsportpagethankyou.ctaLinkSportPageThankYou

  const[count, setCount] = useState(graphqlResult.thankyou.acfsportpagethankyou.redirectTimeSportPageThankYou)
  useEffect(() => {
    const timer = setTimeout(() => {
      setCount(count - 1)
    }, 1000);
    return () => clearTimeout(timer);
  }, [count])

  useEffect(() => {
    if(count === 0) {
      navigate(redirect)
    }
  }, [count])

  return (
    <div>
      <div className="section-header">
        <h1>
          <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
            {page.title}
          </span>
        </h1>
      </div>
      <div className="box box--order thankU">
        <div className={`boxDeco`}>
            <SvgBoxDeco />
        </div>
        <h2>{graphqlResult.thankyou.acfsportpagethankyou.subheaderSportPageThankYou}</h2>
        <h3>{graphqlResult.thankyou.acfsportpagethankyou.subheader2SportPageThankYou}</h3>
        {graphqlResult.thankyou.acfsportpagethankyou.ctaLinkSportPageThankYou && 
        <>
          <AniLink 
            className="link-back cta2" 
            // to={graphqlResult.thankyou.acfsportpagethankyou.ctaLinkSportPageThankYou}
            to={redirect}
            duration={1.6}
            swipe 
            direction="right"
            entryOffset={400}
            data-hover={graphqlResult.thankyou.acfsportpagethankyou.ctaTextSportPageThankYou}
          >
            {graphqlResult.thankyou.acfsportpagethankyou.ctaTextSportPageThankYou}
          </AniLink>
          <span style={{color:'white',fontSize:'16px'}}>
            {graphqlResult.thankyou.acfsportpagethankyou.redirectLabelSportPageThankYou}
            {count}
          </span>
        </>
        }
      </div>
    </div>
  )
}
export default ThankYou