import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SvgBoxDeco from 'src/components/sport/svgBoxDeco'

const Page = ({ page, pageContext }) => {
  // return ''
  const graphqlResult = useStaticQuery(graphql`
  query SportAboutus {
    aboutus: wpCptSportPage(AcfGatsbyId: {pageid: {eq: "about-us"}}) {      
      acfsportpageaboutus {
        buttonMoreSportPageAboutus
        buttonLessSportPageAboutus
      }
    }
  }
`)

const [readMore, setReadMore] = useState(false)

  return (
    <div>
      <div className="section-header">
        <h1>
          <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
            {page.title}
          </span>
        </h1>

      </div>
      <div className={`box box--aboutus ${readMore ? 'box--readmore' : ''} boxWithDeco`}>
        <div className={`boxDeco ${readMore ? 'boxDecoDead' : ''}`}>
          <SvgBoxDeco />
        </div>
        <div className={`wpContent card`} dangerouslySetInnerHTML={{ __html: page.content }}></div>

        <button className="readMore cta2" onClick={() => setReadMore(!readMore)}>{readMore ? graphqlResult.aboutus.acfsportpageaboutus.buttonLessSportPageAboutus : graphqlResult.aboutus.acfsportpageaboutus.buttonMoreSportPageAboutus}</button>
      </div>
    </div>
  )
}


const AboutUs = (props) => {
  return Page(props)
}
export default AboutUs