import React, { useEffect } from 'react'
import { graphql } from 'gatsby'

import SportLayout from 'src/components/layouts/sportLayout'
import Seo from 'src/components/seoWp'

import Start from 'src/components/sport/pages/start'
import Offer from 'src/components/sport/pages/offer'
import Benefits from 'src/components/sport/pages/benefits'
import Order from 'src/components/sport/pages/order'
import AboutUs from 'src/components/sport/pages/aboutUs'
import Contact from 'src/components/sport/pages/contact'
import ThankYou from 'src/components/sport/pages/thankYou'
import Reviews from 'src/components/sport/pages/reviews'
import Clients from 'src/components/sport/pages/clients'
import Default from 'src/components/sport/pages/default'
import OfferDetails from 'src/components/sport/pages/offerDetails'

// import {
//   GlobalStateContext,
//   GlobalDispatchContext,
// } from 'src/context/GlobalContextProvider'
  
// import 'src/styles/global.scss'
// import 'src/styles/swiper.scss'
import 'src/styles/sport.scss'


const SportPageTemplate = ({ data: { page, subpage, subpageMenu, subpage2lev }, pageContext, location }) => {


  // const state = useContext(GlobalStateContext)
  // const dispatch = useContext(GlobalDispatchContext)

  // const currentSectionUpdate = () => {
  //   dispatch({ type: 'CURRENT_SECTION', value: pageContext.siteSlug })
  // }
  const sessionStorageUpdate = () => {
    window.localStorage.setItem("loco", pageContext.siteSlug)
  }
  useEffect(() => {
    // state.currentSection !== pageContext.siteSlug &&
    window.localStorage.getItem("loco") !== pageContext.siteSlug &&
    // currentSectionUpdate()
    sessionStorageUpdate()
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps


  if(typeof page.subpage2lev !== 'undefined') {
    // console.log('PAGE THAT SHOULD HAVE 3 LEVEL URL')
    page.AcfGatsbyId = page.subpage2lev.AcfGatsbyId
    page.acffreshmailsubs = subpage2lev.acffreshmailsubs
  }

  page.subpage = pageContext.subpage
  page.subpage2lev = pageContext.subpage2lev
  if(page.subpage2lev){
    page.subpage2lev.acfsportofferdetails = subpage2lev.acfsportofferdetails    
  }

  const contentMap = {
    start: Start,
    offer: Offer,
    benefits: Benefits,
    'placing-order': Order,
    'about-us': AboutUs,
    contact: Contact,
    'thank-you': ThankYou,
    reviews: Reviews,
    clients: Clients,
    'offer-details': OfferDetails
  }
  const Content = page.AcfGatsbyId.pageid in contentMap ? contentMap[page.AcfGatsbyId.pageid] : Default

  return (
    <React.Fragment>
      <SportLayout pageContext={pageContext} page={page} subpage={subpage} subpage2lev={subpage2lev}>
        <Seo 
          seo={(typeof subpage !== 'undefined' && subpage !== null) ? subpage.seo : page.seo} 
          site={pageContext.siteSlug} 
          // slug={page.slug} 
          slug={(typeof subpage !== 'undefined' && subpage !== null) ? subpage.slug : page.slug}
          gatsbyId={page.AcfGatsbyId.pageid} 
          subpageid={(typeof pageContext.subpage !== 'undefined' && pageContext.subpage.AcfGatsbyId.pageid)}
          subpage2levid={typeof pageContext.subpage2lev !== 'undefined' && pageContext.subpage2lev.AcfGatsbyId.pageid}      
          siteSlug={pageContext.realSlugs[pageContext.siteSlug]}
        />
        <Content pageContext={pageContext} page={page} subpage={subpage} subpageMenu={subpageMenu} subpage2lev={subpage2lev} location={location} />
      </SportLayout>
    </React.Fragment>
  )
}

export default SportPageTemplate

export const pageQuery = graphql`
  query SportPageById($id: String!, $subpageId: String!, $subpage2levId: String!, $pagePath: String!) {
    page: wpCptSportPage(id: { eq: $id }) {
      slug
      id
      title
      content
      AcfGatsbyId {
        pageid
      }
      acffreshmailsubs {
				freshmailSubscriptionCheckbox
        freshmailSubscriptionsListId
        checkboxDescriptionForUsers
      }
      seo {
        focuskw
        title
        canonical
        opengraphUrl
        metaDesc
        schema {
          raw
        }
      }
      featuredImage {
        node {
          altText
          description
          title
          localFile {
            childImageSharp {
              fluid (maxWidth: 1600, quality: 60) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }

      acfsportpagestart_layout {
        videolongSportPageStartLayout
        videoshortSportPageStartLayout {
          localFile {
            publicURL
          }
        }
      }


    }

    subpageMenu: wpMenuItem(childItems: {nodes: {elemMatch: {path: {eq: $pagePath}}}}) {
      childItems {
        nodes {
          id
          path
          label
        }
      }
    }

    subpage: wpCptSportPage(id: { eq: $subpageId }) {
      slug
      title
      id
      content
      seo {
        focuskw
        title
        canonical
        opengraphUrl
        metaDesc
      }
      featuredImage {
        node {
          altText
          description
          title
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 60) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }

      acfsportpageofferbenefits {
        gifFileSportPageOfferBenefits {
          altText
          localFile {
            publicURL
            name
             ext
            base
          }
        }
        fallbackFileSportPageOfferBenefits {
          altText
          localFile {
            publicURL
            name
             ext
            base
          }
        }
        ctaTextSportPageOfferBenefits
        ctaLinkSportPageOfferBenefits
        ctaTextSportPageOfferBenefitsSecond
        ctaLinkSportPageOfferBenefitsSecond   
      }
    }
    subpage2lev: wpCptSportPage(id: { eq: $subpage2levId }) {
      acffreshmailsubs {
				freshmailSubscriptionCheckbox
        freshmailSubscriptionsListId
        checkboxDescriptionForUsers
      }
      acfsportofferdetails{
        bannergroup{
          glowneZdjecie{
            altText
            description
            title
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 60) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          tresc
          przyciskPodTrescia{
            naglowekDlaPrzycisku
            linkPrzycisku
            napisNaPrzycisku
          }
        }
        produktygrupa{
          tytulSekcji
          pojedynczyProdukt{
            tytul
            sliderZdjecia{
              grupaZdjec{
                zdjecie{
                  altText
                  description
                  title
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1000, quality: 60) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
            tresc
            cena
            napisNaPrzycisku
            linkNaPrzycisku
          }
        }
        dlaczegowartogrupa{
          tytulSekcji
          zaleta{
            ikona{
              localFile {
                publicURL
              }
            }
            nazwa
            opis
          }
        }
        sekcjeopisowegrupa{
          sliderZdjecia{
            grupaZdjec{
              zdjecie{
                altText
                description
                title
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 60) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          tytulNadTrescia
          tresc
          powtarzalneTresciZNaglowkiem{
            naglowek
            podnaglowek
          }
        }
        zobaczrealizacjegrupa{
          tlo{
            altText
            description
            title
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          tytulOdnosnika
          linkOdnosnika
        }
        sprawdzinneproduktygrupa{
          zdjecie{
            altText
            description
            title
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 60) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          tytulNadTrescia
          tresc
          przycisk{
            napisNaPrzycisku
            linkPrzycisku
          }
        }
        kontaktgrupa{
          tytul
          tresc
          kontaktContent
          naglowekDlaPrzycisku
          napisNaPrzycisku
          linkPrzycisku
        }
        mediaspolecznosciowegrupa{
          tytul
        }
      }
      slug
      title
      id
      content
      seo {
        focuskw
        title
        canonical
        opengraphUrl
        metaDesc
      }
      featuredImage {
        node {
          altText
          description
          title
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 60) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }

      acfsportpageofferbenefits {
        gifFileSportPageOfferBenefits {
          altText
          localFile {
            publicURL
            name
             ext
            base
          }
        }
        fallbackFileSportPageOfferBenefits {
          altText
          localFile {
            publicURL
            name
             ext
            base
          }
        }
        ctaTextSportPageOfferBenefits
        ctaLinkSportPageOfferBenefits
        ctaTextSportPageOfferBenefitsSecond
        ctaLinkSportPageOfferBenefitsSecond   
      }
    }
  }
`
