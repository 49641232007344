import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import SvgBoxDeco from 'src/components/sport/svgBoxDeco'

const Order = ({ page, pageContext }) => {
  const graphqlResult = useStaticQuery(graphql`
    query SportOrderPosts {
      placingOrder: wpCptSportPage(AcfGatsbyId: {pageid: {eq: "placing-order"}}) {
        acfsportpageplacingorder {
          cta2LinkSportPagePlacingOrder
          cta2TextSportPagePlacingOrder
          ctaLinkSportPagePlacingOrder
          ctaTextSportPagePlacingOrder
          subheader2SportPagePlacingOrder
          subheaderSportPagePlacingOrder
        }
      }
      howtoorder: wpPage(AcfGatsbyId: {pageid: {eq: "how-to-order"}}) {
        slug
        acfpagehowtoorder {
          mailLabelPageHowToOrder
        }
      }
      defaultPhone: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "default-phone"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
      defaultMail: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "default-email"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
    }
  `)
  const phoneHref = graphqlResult.defaultPhone.acfgeneralinfos_layout.lineGenralInfosInfo
  const phoneHrefClean = phoneHref.replace(/\s+/g, '')


  return (
    <div>
      <div className="section-header">
        <h1>
          <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
            {page.title}
          </span>
        </h1>
      </div>
      <div className="box box--order boxWithDeco">
        <div className={`boxDeco`}>
          <SvgBoxDeco />
        </div>
        <h2>{graphqlResult.placingOrder.acfsportpageplacingorder.subheaderSportPagePlacingOrder}</h2>
        {page.content && <div className={`wpContent`} dangerouslySetInnerHTML={{ __html: page.content }}></div>}
        <h3>{graphqlResult.placingOrder.acfsportpageplacingorder.subheader2SportPagePlacingOrder}</h3>
        {graphqlResult.placingOrder.acfsportpageplacingorder.ctaLinkSportPagePlacingOrder && 
        <AniLink 
          className="link fakeButton cta" 
          to={graphqlResult.placingOrder.acfsportpageplacingorder.ctaLinkSportPagePlacingOrder} 
          duration={1.6}
          entryOffset={400}
          swipe 
          direction="left"
          data-hover={graphqlResult.placingOrder.acfsportpageplacingorder.ctaTextSportPagePlacingOrder}
        >
          <span>{graphqlResult.placingOrder.acfsportpageplacingorder.ctaTextSportPagePlacingOrder}</span>
        </AniLink>}
        <a
          className={`link fakeButton cta`}
          href={`tel:${phoneHrefClean}`}
          data-hover={graphqlResult.placingOrder.acfsportpageplacingorder.ctaTextSportPagePlacingOrder}
        >
          <span>{graphqlResult.placingOrder.acfsportpageplacingorder.ctaTextSportPagePlacingOrder}</span>
        </a>
        {graphqlResult.placingOrder.acfsportpageplacingorder.cta2TextSportPagePlacingOrder && 
        <AniLink
          className="howToOrder-link cta2"
          to={graphqlResult.placingOrder.acfsportpageplacingorder.cta2LinkSportPagePlacingOrder}
          duration={1.6}
          swipe
          direction="up"
          entryOffset={400}
          state={{ prevPath: pageContext.siteSlug }}
          dangerouslySetInnerHTML={{ __html: graphqlResult.placingOrder.acfsportpageplacingorder.cta2TextSportPagePlacingOrder }}
        >
        </AniLink>}
        <h3>{graphqlResult.howtoorder.acfpagehowtoorder.mailLabelPageHowToOrder}</h3>
        <a
          href={`mailto:${graphqlResult.defaultMail.acfgeneralinfos_layout.lineGenralInfosInfo}`}
          style={{color:'white',textDecoration:'none'}}
        >
          {graphqlResult.defaultMail.acfgeneralinfos_layout.lineGenralInfosInfo}
        </a>
      </div>
    </div>
  )
}

export default Order
