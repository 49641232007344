import React from 'react'
import SvgBoxDeco from 'src/components/sport/svgBoxDeco'
// import Btn from 'src/components/common/btn'

const Default = ({ page, pageContext }) => {
  return (
    <div>
      <div className="section-header">
        <h1>
          <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
            {page.title}
          </span>
        </h1>
      </div>
      <div className="box box--default boxWithDeco">
        <div className={`boxDeco`}>
          <SvgBoxDeco />
        </div>
        {page.content && <div className={`wpContent`} dangerouslySetInnerHTML={{ __html: page.content }}></div>}

        {/* {page.subtitleAcf.buttonlinkacf && <Btn to={pageContext.sitePath.slice(0, -1) + page.subtitleAcf.buttonlinkacf}>{page.subtitleAcf.buttonacf}</Btn>} */}

      </div>
    </div>
  )
}
export default Default