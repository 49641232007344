import React, { useContext, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import ReactPlayer from 'react-player/lazy'

import {
  GlobalStateContext,
} from 'src/context/GlobalContextProvider'
import SvgBoxDeco from 'src/components/sport/svgBoxDeco'

SwiperCore.use([Navigation, Pagination])


const Reviews = ({ page }) => {
  const videoSlides = useStaticQuery(graphql`
    query SportReviewsSlides {
      allWpCptSportReview {
        edges {
          review: node {
            id
            acfsportreview {
              reviewTitleSportReviews
              reviewPersonSportReviews
              reviewPersonDetailsSportReviews
              reviewTextSportReviews
              reviewVideoSportReviews
            }
          }
        }
      }
      review: wpCptSportPage(AcfGatsbyId: {pageid: {eq: "reviews"}}) {      
        acfsportpagereviews {
          subheaderSportPageReviews
        }
      }
      siteLanguage: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "language"}}) {
        acfgeneralinfos_layout {
          lineGenralInfosInfo
        }
      }
    }
  `)
  const state = useContext(GlobalStateContext)
  const reviews = videoSlides.allWpCptSportReview.edges
  const currentLang = videoSlides.siteLanguage.acfgeneralinfos_layout.lineGenralInfosInfo
  const ytLang = currentLang.slice(0, currentLang.lastIndexOf('-'))

  const [swiper, setSwiper] = useState(null)


  return (
    <div>
      <div className="section-header">
        <h1 className={``}>
          <span className={page.title.length > 10 ? 'heading--toLong' : page.title.length > 8 ? 'heading--long' : ''}>
            {page.title}
          </span>
        </h1>
        <h2>{videoSlides.review.acfsportpagereviews.subheaderSportPageReviews}</h2>
        {page.content && <div className={`wpContent`} dangerouslySetInnerHTML={{ __html: page.content }}></div>}
      </div>
      <div 
        className="box box--reviews boxWithDeco" 
      >
        <div className={`boxDeco`}>
          <SvgBoxDeco />
        </div>
        <Swiper
          observer={true}
          observeParents={true}
          rebuildOnUpdate={true}
          spaceBetween={200}
          treshold={50}
          navigation
          loop={true}
          onSwiper={(s) => setSwiper(s)}
        >
          {[...reviews].reverse().map(({ review, key }) => {
            return (
              <SwiperSlide key={key}>
                {({ isActive }) => (
                <>
                  <ReactPlayer 
                    url={`${review.acfsportreview.reviewVideoSportReviews}${isActive ? '&amp;enablejsapi=1' : '&amp;end=1'}`} 

                    width={state.windowWidth > 1063 ? '600' : state.windowWidth > 760 ? '560' : state.windowWidth > 488 ? `${state.windowWidth * 0.8 - 40}` : `${state.windowWidth - 40}`}

                    onEnded={() => swiper.slideNext(100, false)} //  2021 09 02
                    playing={isActive && true}
                    muted={true}

                    controls={true}
                    config={{
                      youtube: {
                        playerVars: { 
                          cc_lang_pref: ytLang,
                          hl: ytLang,
                          cc_load_policy: 1
                        }
                      }
                    }}
                  />

                  <h2>
                    {review.acfsportreview.reviewPersonSportReviews}
                  </h2>
                  <span className={`personDetails`}>{review.acfsportreview.reviewPersonDetailsSportReviews}</span>
                </>
                )}
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}
export default Reviews