import React, { Children, useState, useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import parse from 'html-react-parser'
import 'src/styles/offerDetails.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from "swiper";
import ContactForm from 'src/components/common/contactFormFile'

const OfferDetailsPage = ({ page, pageContext }, graphqlResult) => {

  const pageFields = page.subpage2lev.acfsportofferdetails

  // console.log(page)
  // console.log(pageContext)

  // INSTAGRAM //

    const token = `IGQVJXLVVHVEdkdThNY2NaUmpZAMUExVlpnUVVfVHAycDd4TFdtRGlGVlJOQVFlMm9sVnNRMm53TmV2ZA0NUbF80Q3N0MDVEX1lmemdjNFMteC1oZA0ZASLTMtdnBNZADhXWGVHNVc2dXpvQXFIeE04WGdkOAZDZD`
    const counter = `20`
    const placeholder = useRef();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [showImage, setShowImage] = useState(false);

    const type = showImage ? 'hidePlaceholder' : 'placeholder';

    let url = `https://graph.instagram.com/me/media?fields=media_count,media_type,permalink,media_url,caption&&access_token=${token}`;

    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);
        fetch(url)
          .then(response => response.json())
          .then(result => {
            setData(result.data)
            console.log(result.data)
          })
          .catch((error) => setIsError(true));
        setIsLoading(false);
      };

      fetchData();

      const callback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShowImage(true);
          }
        });
      };

      const options = {
        threshold: 1.0
      };

      const observer = new IntersectionObserver(callback, options);
      observer.observe(placeholder.current);

      // intersection observer set-up
      return () => observer.disconnect();

    }, [url]);

  // END INSTAGRAM //

  return (
    <div id="offerDetails">

        <section className={`bannerGroup`}>
        
          <div className={`flexWrapper`}>

            <div className={`leftWrapper`}>
              {pageFields.bannergroup.glowneZdjecie &&
                <Image
                    fluid={pageFields.bannergroup.glowneZdjecie.localFile.childImageSharp.fluid}
                    alt={pageFields.bannergroup.glowneZdjecie.altText}
                    title={pageFields.bannergroup.glowneZdjecie.title}
                    description={pageFields.bannergroup.glowneZdjecie.localFile.description}
                    style={{maxWidth: "600px"}}
                  />
              }
            </div>

            <div className={`rightWrapper`}>

              {pageFields.bannergroup.tresc &&
                parse(pageFields.bannergroup.tresc)
              }
              
              <div className={`buttonWrapper`}>
                {pageFields.bannergroup.przyciskPodTrescia.naglowekDlaPrzycisku &&
                  <p>{pageFields.bannergroup.przyciskPodTrescia.naglowekDlaPrzycisku}</p>
                }
                {pageFields.bannergroup.przyciskPodTrescia.linkPrzycisku &&
                  <button><a href={pageFields.bannergroup.przyciskPodTrescia.linkPrzycisku}>{pageFields.bannergroup.przyciskPodTrescia.napisNaPrzycisku}</a></button>
                }
              </div>

            </div>

          </div>

          <div className={`scrollDown`}>
            <a href="#scroll">»</a>
          </div>

        </section>

        {pageFields.produktygrupa.tytulSekcji && 

          <section className={`produktyGrupa`} id={`scroll`}>

            {pageFields.produktygrupa.tytulSekcji &&
              <h2>{pageFields.produktygrupa.tytulSekcji}</h2> 
            }

            <div className={`flexWrapper`}>

            {pageFields.produktygrupa.pojedynczyProdukt &&
    
              pageFields.produktygrupa.pojedynczyProdukt.map((item, key) => {
                return(

                  <div className={`produktWrapper`} key={key}>

                      {item.tytul &&
                        <h3>{item.tytul}</h3>
                      }

                      <Swiper
                        spaceBetween={20}
                        slidesPerView={1}
                        pagination={{
                          clickable: true,
                        }}
                        autoplay={{
                          delay: 4000,
                          disableOnInteraction: false,
                        }}
                        loop={true}
                        modules={[Pagination]}
                        className="produktSwiper"
                      >

                        {item.sliderZdjecia.grupaZdjec &&

                          item.sliderZdjecia.grupaZdjec.map((itemGrupaZdjec, key) => {
                            return(
                              <SwiperSlide key={key}>
                                <Image
                                  fluid={itemGrupaZdjec.zdjecie.localFile.childImageSharp.fluid}
                                  alt={itemGrupaZdjec.zdjecie.altText}
                                  title={itemGrupaZdjec.zdjecie.title}
                                  description={itemGrupaZdjec.zdjecie.localFile.description}
                                />
                              </SwiperSlide>
                            )
                          })

                        }

                      </Swiper>

                      {item.tresc && 
                        parse(item.tresc)
                      }

                      {item.cena && 
                        <p className={`cena`}>{item.cena}</p>
                      }
                      
                      {item.linkNaPrzycisku &&
                        <button><a href={item.linkNaPrzycisku}>{item.napisNaPrzycisku}</a></button>
                      }
                      
                  </div>

                )
              })

            }


            </div>

          </section>
        }

        <section className={`dlaczegoMyGrupa`}>

          {pageFields.dlaczegowartogrupa.tytulSekcji &&
            <h2>{pageFields.dlaczegowartogrupa.tytulSekcji}</h2>
          }
          
          <div className={`flexWrapper`}>

            {pageFields.dlaczegowartogrupa.zaleta &&
            
              pageFields.dlaczegowartogrupa.zaleta.map((item, key) => {
                return(
                  
                  <div className={`singleZaleta`} key={key}>

                    <div className={`gatsby-image-wrapper`}>
                      {item.ikona &&
                        <img src={item.ikona.localFile.publicURL} />
                      }
                    </div>

                    {item.nazwa &&
                      <h4>{item.nazwa}</h4>
                    }

                    {item.opis &&
                      <p>{item.opis}</p>
                    }
                    
                  </div>

                )
              })
            
            }

          </div>

        </section>


        {pageFields.sekcjeopisowegrupa &&

          <div className={`sekcjeOpisoweGrupa`}>

            {pageFields.sekcjeopisowegrupa.map((sekcjaOpisowa, key) => {
              return(

                <section className={`sekcjaOpisowa`} key={key}>

                  <div className={`flexWrapper`}>

                    <div className={`leftWrapper`}>

                        <Swiper
                          spaceBetween={20}
                          slidesPerView={1}
                          pagination={{
                            clickable: true,
                          }}
                          autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                          }}
                          loop={true}
                          modules={[Pagination]}
                          className="produktSwiper"
                        >

                          {sekcjaOpisowa.sliderZdjecia.grupaZdjec &&

                            sekcjaOpisowa.sliderZdjecia.grupaZdjec.map((itemGrupaZdjec, key) => {
                              return(
                                <SwiperSlide key={key}>
                                  <Image
                                    fluid={itemGrupaZdjec.zdjecie.localFile.childImageSharp.fluid}
                                    alt={itemGrupaZdjec.zdjecie.altText}
                                    title={itemGrupaZdjec.zdjecie.title}
                                    description={itemGrupaZdjec.zdjecie.localFile.description}
                                  />
                                </SwiperSlide>
                              )
                            })

                          }

                        </Swiper>

                    </div>

                    <div className={`rightWrapper`}>

                      {sekcjaOpisowa.tytulNadTrescia &&
                        <h3>{sekcjaOpisowa.tytulNadTrescia}</h3>
                      }
                      
                      <div className={`tresc`}>
                        {sekcjaOpisowa.tresc &&
                          parse(sekcjaOpisowa.tresc)
                        }
                      </div>

                      {sekcjaOpisowa.powtarzalneTresciZNaglowkiem &&

                        <div className={`itemsWrapper`}>

                          {sekcjaOpisowa.powtarzalneTresciZNaglowkiem.map((item, key) => {
                            return(
                              <div className={`singleItem`} key={key}>
                                <h4>{item.naglowek}</h4>
                                <p>{item.podnaglowek}</p>
                              </div>

                            )
                          })}

                        </div>

                      }

                    </div>

                  </div>

                </section>

              )
            })}

          </div>

        }


        <a href={pageFields.zobaczrealizacjegrupa.linkOdnosnika} target="_blank">
          <section className={`zobaczRealizacjeGrupa`}>
            
            {pageFields.zobaczrealizacjegrupa.tlo && 
              <Image
                fluid={pageFields.zobaczrealizacjegrupa.tlo.localFile.childImageSharp.fluid}
                alt={pageFields.zobaczrealizacjegrupa.tlo.altText}
                title={pageFields.zobaczrealizacjegrupa.tlo.title}
                description={pageFields.zobaczrealizacjegrupa.tlo.localFile.description}
                className={`sectionBg`}
              />
            }

            {pageFields.zobaczrealizacjegrupa.tytulOdnosnika &&
              <h2>{pageFields.zobaczrealizacjegrupa.tytulOdnosnika}</h2>
            }

          </section>
        </a>

        <section className={`sprawdzInneProduktyGrupa`}>

          <div className={`flexWrapper`}>

            <div className={`leftWrapper`}>

                {pageFields.sprawdzinneproduktygrupa.zdjecie &&
                  <Image
                    fluid={pageFields.sprawdzinneproduktygrupa.zdjecie.localFile.childImageSharp.fluid}
                    alt={pageFields.sprawdzinneproduktygrupa.zdjecie.altText}
                    title={pageFields.sprawdzinneproduktygrupa.zdjecie.title}
                    description={pageFields.sprawdzinneproduktygrupa.zdjecie.localFile.description}
                  />
                }

            </div>

            <div className={`rightWrapper`}>

              {pageFields.sprawdzinneproduktygrupa.tytulNadTrescia &&
                <h3>{pageFields.sprawdzinneproduktygrupa.tytulNadTrescia}</h3>
              }
              
              <div className={`tresc`}>
                {pageFields.sprawdzinneproduktygrupa.tresc &&
                  parse(pageFields.sprawdzinneproduktygrupa.tresc)
                }
              </div>

              <div className={`buttonsWrapper`}>

                {pageFields.sprawdzinneproduktygrupa.przycisk &&

                  pageFields.sprawdzinneproduktygrupa.przycisk.map((item, key) => {
                    return(
                      <button key={key}><a href={item.linkPrzycisku}>{item.napisNaPrzycisku}</a></button>
                    )
                  })

                }

              </div>

            </div>
          
          </div>

        </section>

        
        <section className={`kontaktGrupa`} id="form">

          <div className={`flexWrapper`}>

            <div className={`leftWrapper`}>
              
              {pageFields.kontaktgrupa.tytul &&
                <h3>{pageFields.kontaktgrupa.tytul}</h3>
              }
              
              <div className={`tresc`}>
                {pageFields.kontaktgrupa.tresc &&
                  parse(pageFields.kontaktgrupa.tresc)
                }
              </div>

              <div className={`kontaktContent wpContent`}>
                {pageFields.kontaktgrupa.kontaktContent &&
                  parse(pageFields.kontaktgrupa.kontaktContent)
                }
              </div>

              <div className={`linkWrapper`}>
                {pageFields.kontaktgrupa.naglowekDlaPrzycisku &&
                  <h3>{pageFields.kontaktgrupa.naglowekDlaPrzycisku}</h3>
                }
                {pageFields.kontaktgrupa.linkPrzycisku &&
                  <button><a href={pageFields.kontaktgrupa.linkPrzycisku} target="_blank">{pageFields.kontaktgrupa.napisNaPrzycisku}</a></button>
                }
              </div>

            </div>

            <div className={`rightWrapper contact`}>

              <div className="formularz">
                <ContactForm pageContext={pageContext} thankyou={graphqlResult.thankyou.slug} subscription={page.acffreshmailsubs} />
              </div>
              
            </div>
          
          </div>

        </section>

        { data && 
          <section className={`instagram`}>
            
            {pageFields.kontaktgrupa.tytul &&
              <h2>{pageFields.mediaspolecznosciowegrupa.tytul}</h2>
            }

            <div>
              {isLoading ? (<div> Ładowanie... </div>)
                : isError ? (
                  <div>
                    <p className="errorMessage"> Access Token jest niepoprawny</p>
                  </div>)
                  : (
                    <div className={`instagramItems`} ref={placeholder}>

                      {showImage && (
                        <Swiper
                          spaceBetween={20}
                          navigation
                          modules={[Navigation]}
                          className="instagramSwiper"
                          breakpoints={{
                            500: {
                              slidesPerView: 1,
                            },
                            768: {
                              slidesPerView: 2,
                            },
                            1024: {
                              slidesPerView: 4,
                            },
                          }}
                        >

                          {data.slice(0, counter).map((item, index) => {
                            return(
                              <>
                              {item.media_type !== 'VIDEO' &&
                                <SwiperSlide key={index} className={`instagramItem`}>
                                  <div
                                  key={index}
                                  className="ig-instagram-link"
                                  >
                                    {(item.media_type === 'IMAGE' || item.media_type === 'CAROUSEL_ALBUM') ?

                                      <a href={item.permalink} target="_blank">
                                        <img
                                          className={`instagramImg`}
                                          key={index}
                                          src={item.media_url}
                                          alt="description"
                                        /> 
                                      </a>
                                      : ``
                                    }

                                </div>
                                </SwiperSlide>
                              }
                              </>
                            )
                          })}

                        </Swiper>
                        )}

                    </div>
                    )}
            </div>
          </section>
        }

    </div>
  )
}

const OfferDetails = (props) => {
  const graphqlResult = useStaticQuery(graphql`
  query SportOfferDetailsPage {

    siteLanguage: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "language"}}) {
      acfgeneralinfos_layout {
        lineGenralInfosInfo
      }
    }
    thankyou: wpCptSportPage(AcfGatsbyId: {pageid: {eq: "thank-you"}}) {
      slug
    }
  }
`)
return OfferDetailsPage(props,graphqlResult)
}

export default OfferDetails
